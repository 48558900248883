
import AdminContentEditable from '~/mixins/AdminContentEditable'
import ContentCreator from '../HeocademySiteBuilder/ContentCreator.vue'
import RemoveContent from '../HeocademySiteBuilder/RemoveContent.vue'
import Modal from '@/components/Shared/Modal/index.vue'
export default {
  components: { ContentCreator, RemoveContent, Modal },
  mixins: [AdminContentEditable],
  data() {
    return {
      edit: {
        show: false,
        item: null,
      },
      create: {
        show: false,
        item: {
          description: '',
          title: '',
          overview: '',
          degrees: 1,
        },
      },
      showCreator: false,
      removeContentItem: { show: false, id: -1 },
      activeIndex: 0,
      swiperOption: {
        autoplay: 10000,
        slidesPerView: 1,
        loop: false,
        initialSlide: 0,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        },
        onSlideChangeEnd: (swiper) => {
          // can do something
          vm.activeIndex = swiper.activeIndex
        },
      },
      testimonials: this.$store.state.settings.contents.testimonials,
    }
  },
  methods: {
    UPDATE_CONTENT() {
      location.reload()
    },
    async SAVE_EDIT_COMMENT(loading) {
      const fd = new FormData()
      fd.append('Description', this.edit.item.description)
      fd.append('Title', this.edit.item.title)
      fd.append('Overview', this.edit.item.overview)
      fd.append('Degrees', this.edit.item.degrees)
      loading(true)
      const response = await this.CONTEXT_REQUEST(
        'PUT',
        `Contents/${this.edit.item.id}`,
        fd
      )
      if (response.message === 'OK') {
        // this.edit.show = false
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')

        location.reload()
      }
    },
    async SAVE_CREATE_COMMENT(loading) {
      const fd = new FormData()
      fd.append('Description', this.create.item.description)
      fd.append('Title', this.create.item.title)
      fd.append('Overview', this.create.item.overview)
      fd.append('Degrees', this.create.item.degrees)
      fd.append('Type', 7)
      fd.append('Language', this.$i18n.locale)
      loading(true)
      const response = await this.CONTEXT_REQUEST(
        'POST',
        `Contents?companyId=${this.getSettings.id}`,
        fd
      )
      if (response.message === 'OK') {
        // this.edit.show = false
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')

        location.reload()
      }
    },
  },
  created() {
    this.testimonials = this.$store.state.settings.contents.testimonials
  },
}
